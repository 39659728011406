// Hero.js
import React from "react";
import {
    HeroContainer,
    HeroHeading,
    HeroSubtitle,
    HeroButton
} from "./AboutHeroElements";

const Hero = () => {
  return (
    <HeroContainer>
      <HeroHeading>About Us</HeroHeading>
      <HeroSubtitle>
      We are your trusted partner in navigating the complex world of finance to help you achieve your businesses financial goals effortlessly. 
      </HeroSubtitle>
      <HeroButton to="/contact">Contact Us</HeroButton>
    </HeroContainer>
  );
};

export default Hero;
