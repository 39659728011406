import React from "react";

import img from "../../../images/icons/case-study.svg"

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  Img,
  ImgWrap,
  Button,
} from "./TestimonialsElements";

const InfoSection = ({}) => {
  return (
    <>
      <InfoContainer >
        <InfoWrapper>
          <InfoRow >
            <Column1>
              <TextWrapper>
                <TopLine>What others say</TopLine>
                <Heading >Check out our Case Studies</Heading>
                <Subtitle>
                  At Blessi Accounting we excell in giving the best customer service. Just see what our clients say about us. 
                </Subtitle>
                <BtnWrap>
                <Button to="/case-studies">See All Case Studies</Button>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
