
import React from 'react';
import { animateScroll as scroll } from 'react-scroll';

// Other Components
import BurgerMenu from '../BurgerMenu/index.js';

// Images
import logo from "../images/logos/blessi-horiz2.png";

// Styling
import {
    NavbarContainer,
    Logo,
    Menu,
    MenuItem,
    NavLink,
    NavLink2,
} from "./NavbarElements.js"


const Navbar = () => {

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <NavbarContainer >
        <Logo src={logo} alt="Blessi Logo" loading="lazy"
        onClick={scrollToTop} />
      <BurgerMenu />
      <Menu >
        <MenuItem>
          <NavLink to="/">Home</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/about">About</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/services">Services</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/case-studies">Case Studies</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink2 to="/contact">Contact</NavLink2>
        </MenuItem>
      </Menu>
    </NavbarContainer>
  );
}

export default Navbar;
