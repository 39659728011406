import styled from "styled-components";

export const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 50px;
  background-color: #fff; /* Change background color as needed */

  @media screen and (max-width: 1141px) {
    flex-direction: column;
    padding: 20px;
  }
`;

export const BannerImage = styled.img`
  width: 100%; /* Adjust width as necessary */
  max-width: 450px; /* Optional: to prevent the images from getting too large */
  height: auto;
  margin: 0 10%;

  @media screen and (max-width: 1141px) {
    width: 80%;
    margin: 50px;
  }
`;
