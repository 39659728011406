// CaseStudyDetail.js
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { caseStudies } from "../../../data";

import {
  DetailContainer,
  DetailWrapper,
  DetailImage,
  DetailTitle,
  Section,
  SectionTitle,
  SectionText,
  BulletList,
  BulletItem,
  Quote,
  QuoteAuthor,
  BackButton
} from "./CaseStudyDetailElements";



const CaseStudyDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const caseStudy = caseStudies.find(cs => cs.slug === slug);

  if (!caseStudy) {
    return <div>Case study not found</div>;
  }

  return (
    <DetailContainer>
      <DetailWrapper>
        <DetailImage src={caseStudy.image} alt={caseStudy.company} />
        <DetailTitle>{caseStudy.title}</DetailTitle>
        <Section>
          <SectionTitle>Overview</SectionTitle>
          <SectionText>{caseStudy.overview}</SectionText>
        </Section>
        <Section>
          <SectionTitle>Challenge</SectionTitle>
          <SectionText>{caseStudy.challenge}</SectionText>
        </Section>
        <Section>
          <SectionTitle>Solution</SectionTitle>
          <SectionText>{caseStudy.solution}</SectionText>
          <BulletList>
            {caseStudy.bulletPoints.map((point, index) => (
              <BulletItem key={index}>{point}</BulletItem>
            ))}
          </BulletList>
        </Section>
        <Section>
          <SectionTitle>Outcome</SectionTitle>
          <SectionText>{caseStudy.outcome}</SectionText>
        </Section>
        <Section>
          <Quote>"{caseStudy.quote}"</Quote>
          <QuoteAuthor>
            - {caseStudy.name}, {caseStudy.role}, {caseStudy.company}
          </QuoteAuthor>
        </Section>
        <BackButton onClick={() => navigate(-1)}>Go Back</BackButton>
      </DetailWrapper>
    </DetailContainer>
  );
};

export default CaseStudyDetail;
