// CaseStudyDetailElements.js
import styled from "styled-components";

export const DetailContainer = styled.div`
  padding: 50px 20px;
  background-color: #f9f9f9;
  min-height: 100vh;
`;

export const DetailWrapper = styled.div`
  max-width: 900px;
  width: 90%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
`;

export const DetailImage = styled.img`
  width: auto;
  border-radius: 10px;
  margin-bottom: 20px;
`;

export const DetailTitle = styled.h1`
  font-size: 28px;
  margin-bottom: 20px;
`;

export const Section = styled.div`
  margin-bottom: 30px;
`;

export const SectionTitle = styled.h2`
  font-size: 22px;
  color: #333;
  margin-bottom: 10px;
`;

export const SectionText = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #666;
`;

export const BulletList = styled.ul`
  margin-left: 20px;
`;

export const BulletItem = styled.li`
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 5px;
`;

export const Quote = styled.blockquote`
  font-size: 18px;
  font-style: italic;
  color: #333;
  border-left: 4px solid #6DC8DD;
  padding-left: 20px;
  margin: 20px 0;
`;

export const QuoteAuthor = styled.p`
  font-size: 16px;
  color: #333;
  font-weight: bold;
  margin-top: 10px;
`;

export const BackButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #6DC8DD;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: #808385;
  }
`;
