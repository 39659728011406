// ContactFormElements.js
import styled from "styled-components";

export const ContactFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  background-color: #f9f9f9;
  min-height: auto;
`;

export const FormWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const FormTitle = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FormLabel = styled.label`
  font-size: 14px;
  margin-bottom: 5px;
`;

export const FormInput = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  outline: none;

  &:focus {
    border-color: #6DC8DD;
  }
`;

export const FormTextArea = styled.textarea`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  resize: vertical;

  &:focus {
    border-color: #6DC8DD;
  }
`;

export const FormButton = styled.button`
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #6DC8DD;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #808385;
  }
`;

export const FormMessage = styled.p`
  margin-top: 10px;
  font-size: 14px;
  color: ${props => (props.error ? 'red' : 'green')};
  text-align: center;
`;

export const SuccessMessage = styled.p`
color: green;
text-align: center;
font-size: 16px;
margin: 20px;
`;

export const ErrorMessage = styled.p`
color: red;
text-align: center;
font-size: 16px;
margin: 20px;
`;
