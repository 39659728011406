// Hero.js
import React from "react";
import {
    HeroContainer,
    HeroHeading,
    HeroSubtitle,
    HeroButton
} from "./ServiceHeroElements";

const Hero = () => {
  return (
    <HeroContainer>
      <HeroHeading>Our Services</HeroHeading>
      <HeroSubtitle>
      Are you a business owner looking for <br />expert financial guidance? 
      </HeroSubtitle>
      <HeroButton to="/contact">Contact Us</HeroButton>
    </HeroContainer>
  );
};

export default Hero;
