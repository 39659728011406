// AboutText.js
import React from "react";
import { AboutTextContainer, AboutHeading, AboutParagraph } from "./AboutTextElements";

const AboutText = () => {
  return (
    <AboutTextContainer>
      <AboutHeading>About Blessi</AboutHeading>
      <AboutParagraph>
      Led by Emma Taylor, a qualified seasoned Chartered Management Accountant, with 20 years’ experience in the field, Blessi Accounting provides top-notch value-added financial services to businesses.
      </AboutParagraph>
      <AboutParagraph>
      We understand that every business is unique, which is why we take a personalised approach to meet your specific needs and goals. Whether you're struggling to keep your finances on track or looking to take your business to new heights, we're here to support you every step of the way. 
      </AboutParagraph>
      <AboutParagraph>
      Beyond numbers, we believe in building strong relationships with our clients, rooted in trust, transparency and integrity. When you partner with us, you can expect dedicated support, innovative solutions and a commitment to your success. 
      </AboutParagraph>
      <AboutParagraph>
      We understand the importance of having a reliable and knowledgeable financial advisor by your side. If you feel your finances aren't where you would expect them to be or where you know they could be, we are here to support you. We can help you make informed decisions that drive business growth and profitability. A strategic approach ensures that your financial goals align with your overall business objectives. 
      </AboutParagraph>
      <AboutParagraph>
      “I want to help more businesses understand and optimise their financial position. Whether they are seeking fresh insights, innovative solutions, or simply a different perspective, I can offer my 20 years expertise in the industry and problem-solving thinking. I have a keen eye for detail and a knack for identifying opportunities.”
      </AboutParagraph>
    </AboutTextContainer>
  );
};

export default AboutText;
