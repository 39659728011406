import React from "react";

import img from "../../../images/icons/masterMoney.svg"

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  Heading,
  Subtitle,
  Img,
  ImgWrap,
} from "./Service01Elements";

const Service01 = ({}) => {
  return (
    <>
      <InfoContainer >
        <InfoWrapper>
          <InfoRow >
            <Column1>
              <TextWrapper>
                <Heading >
                Mastering Money 
                </Heading>
                <Subtitle>
                Navigating the complexities of financial management can be daunting for any business owner. At Blessi Accounting, we understand the challenges you face, from budget constraints to cashflow uncertainties. We can provide comprehensive solutions to your financial woes. With thorough planning, innovative problem-solving, and a commitment to your success, we help you to overcome obstacles, seize opportunities, and achieve your business goals with confidence. 
                </Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default Service01;
