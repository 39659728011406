// Hero.js
import React from "react";
import {
    HeroContainer,
    HeroHeading,
    HeroSubtitle,
} from "./ContactHeroElements";

const Hero = () => {
  return (
    <HeroContainer>
      <HeroHeading>Contact Us</HeroHeading>
      <HeroSubtitle>
      We are your trusted partner in navigating the complex world of finance to help you achieve your businesses financial goals effortlessly. 
      </HeroSubtitle>
      </HeroContainer>
  );
};

export default Hero;
