import React from "react";

// Components
import Navbar from "../Navbar"
import CaseStudiesHero from "../AllPageSections/CaseStudyPageSections/CaseStudyHero"
import CaseStudySection from "../AllPageSections/CaseStudyPageSections/CaseStudySection";
import CaseStudyBoxSection from "../AllPageSections/CaseStudyPageSections/CaseStudyBoxSection";
import Footer from "../Footer";

const CaseStudies = () => {
 
    return (
      <>
      <Navbar />
      <CaseStudiesHero />
      <CaseStudySection />
      <CaseStudyBoxSection />
      <Footer />
      </>
    );
  };

  
export default CaseStudies;