import React from "react";
import {
    SectionContainer,
    Column,
    ColumnHeading,
    ColumnText,
    ColumnButton
} from "./ContactBoxElements";

const ContactBoxSection = () => {
  return (
    <SectionContainer>
    <Column>
      <ColumnHeading>About Blessi</ColumnHeading>
      <ColumnText>
      We are your trusted partner in navigating the complex world of finance to help you achieve your businesses financial goals effortlessly. 
      </ColumnText>
      <ColumnButton to="/about">Learn More</ColumnButton>
    </Column>
    
      <Column>
        <ColumnHeading>Our Services</ColumnHeading>
        <ColumnText>
        Ready to take control of your finances? Learn about the services we offer at Blessi Accounting. 
        </ColumnText>
        <ColumnButton to="/services">Learn More</ColumnButton>
      </Column>
      
      <Column>
        <ColumnHeading>Case Studies</ColumnHeading>
        <ColumnText>
        At Blessi Accounting we excell in giving the best customer service. Just see what our clients say about us.
        </ColumnText>
        <ColumnButton to="/case-studies">Case Studies</ColumnButton>
      </Column>
    </SectionContainer>
  );
};

export default ContactBoxSection;
