// Hero.js
import React from "react";
import {
    HeroContainer,
    HeroHeading,
    HeroSubtitle,
    HeroButton
} from "./CaseStudyHeroElements";

const Hero = () => {
  return (
    <HeroContainer>
      <HeroHeading>Case Studies</HeroHeading>
      <HeroSubtitle>
      At Blessi Accounting we excell in giving the best customer service. Just see what our clients say about us.
      </HeroSubtitle>
      <HeroButton to="/contact">Contact Us</HeroButton>
    </HeroContainer>
  );
};

export default Hero;
