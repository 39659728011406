// ContactForm.js
import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";

import {
  ContactFormContainer,
  FormWrapper,
  FormTitle,
  Form,
  FormLabel,
  FormInput,
  FormTextArea,
  FormButton,
  SuccessMessage,
  ErrorMessage
  
} from "./ContactFormElements";

const ContactForm = () => {
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isVerified, setIsVerified] = useState(false);

  const nameInputRef = useRef();
  const emailInputRef = useRef();
  const messageInputRef = useRef();
  const recaptchaRef = useRef(); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !nameInputRef.current.value ||
      !emailInputRef.current.value ||
      !messageInputRef.current.value
    ) {
      setErrorMessage("Please fill out all fields.");
      return; // Exit function if any field is empty
    }

    // Check if reCAPTCHA is verified
    if (!isVerified) {
      setErrorMessage("Please verify reCAPTCHA.");
      return; // Exit function if reCAPTCHA is not verified
    }

    try {
      const response = await emailjs.send(
        "blessi_service_czyshld",
        "template_wqmlhar",
        {
          name: nameInputRef.current.value,
          email: emailInputRef.current.value,
          message: messageInputRef.current.value
        },
        "HF8QwVDZjuCl6PrZQ"
      );

      setSuccessMessage("Thank you! A member of the team will be in touch soon.");
      setErrorMessage(null);
      resetFormFields();
    } catch (error) {
      setErrorMessage("Sorry, something went wrong. Please try again.");
      setSuccessMessage(null);
      resetFormFields();
    }
  };

  const handleRecaptchaChange = (token) => {
    if (token) {
      setIsVerified(true);
      setErrorMessage(null);
    }
  };

  const resetFormFields = () => {
    nameInputRef.current.value = "";
    emailInputRef.current.value = "";
    messageInputRef.current.value = "";
    setIsVerified(false);
  };

  return (
    <ContactFormContainer>
      <FormWrapper>
        <FormTitle>Contact Us Today</FormTitle>
        <Form>
          <FormLabel htmlFor="name">Name</FormLabel>
          <FormInput
            type="text"
            id="name"
            name="name"
            ref={nameInputRef}
            required
          />

          <FormLabel htmlFor="email">Email</FormLabel>
          <FormInput
            type="email"
            id="email"
            name="email"
            ref={emailInputRef}
            required
          />

          <FormLabel htmlFor="message">Message</FormLabel>
          <FormTextArea
            id="message"
            name="message"
            rows="5"
            ref={messageInputRef}
            required
          />

          <FormButton onClick={handleSubmit}>Submit</FormButton>
        </Form>
            {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}<ReCAPTCHA
              ref={recaptchaRef}
              sitekey={"6LeTcR8qAAAAAOehvxaGa4M_rSOYDIg6U5inQctN"}
              theme="dark"
              size="compact"
              onChange={handleRecaptchaChange}
            />
      </FormWrapper>
    </ContactFormContainer>
  );
};

export default ContactForm;
