// ThreeColumnSection.js
import React from "react";
import {
    SectionContainer,
    Column,
    ColumnHeading,
    ColumnText,
    ColumnButton
} from "./CaseStudyBoxElements";

const CaseStudyBoxSection = () => {
  return (
    <SectionContainer>
      <Column>
        <ColumnHeading>About Blessi</ColumnHeading>
        <ColumnText>
        We are your trusted partner in navigating the complex world of finance to help you achieve your businesses financial goals effortlessly. 
        </ColumnText>
        <ColumnButton to="/about">Learn More</ColumnButton>
      </Column>
      
      <Column>
        <ColumnHeading>Our Services</ColumnHeading>
        <ColumnText>
        Ready to take control of your finances? Learn about the services we offer at Blessi Accounting. 
        </ColumnText>
        <ColumnButton to="/services">Learn More</ColumnButton>
      </Column>
      
      <Column>
        <ColumnHeading>Get In Touch</ColumnHeading>
        <ColumnText>
        Get in touch to discuss how we can become your trusted finance partner. 
        </ColumnText>
        <ColumnButton to="/contact">Get In Touch</ColumnButton>
      </Column>
    </SectionContainer>
  );
};

export default CaseStudyBoxSection;
