// ThreeColumnSection.js
import React from "react";
import {
    SectionContainer,
    Column,
    ColumnHeading,
    ColumnText,
    ColumnButton
} from "./AboutBoxElements";

const AboutBoxSection = () => {
  return (
    <SectionContainer>
      <Column>
        <ColumnHeading>Our Services</ColumnHeading>
        <ColumnText>
        Ready to take control of your finances? Learn about the services we offer at Blessi Accounting. 
        </ColumnText>
        <ColumnButton to="/services">Learn More</ColumnButton>
      </Column>
      
      <Column>
        <ColumnHeading>Case Studies</ColumnHeading>
        <ColumnText>
        At Blessi Accounting we excell in giving the best customer service. Just see what our clients say about us.
        </ColumnText>
        <ColumnButton to="/case-studies">Case Studies</ColumnButton>
      </Column>
      
      <Column>
        <ColumnHeading>Get In Touch</ColumnHeading>
        <ColumnText>
        Get in touch to discuss how we can become your trusted finance partner. 
        </ColumnText>
        <ColumnButton to="/contact">Get In Touch</ColumnButton>
      </Column>
    </SectionContainer>
  );
};

export default AboutBoxSection;
