import React from "react";

// Components
import Navbar from "../Navbar"
import ContactHero from "../AllPageSections/ContactPageSections/ContactHero"
import ContactForm from "../AllPageSections/ContactPageSections/ContactForm";
import ContactBoxSection from "../AllPageSections/ContactPageSections/ContactBoxSection";
import ImageBanner from "../ImageBanner";
import Footer from "../Footer";

const Contact = () => {
 
    return (
      <>
      <Navbar />
      <ContactHero />
      <ContactForm />
      <ImageBanner />
      <ContactBoxSection />
      <Footer />

      </>
    );
  };

  
export default Contact;