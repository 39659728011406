import React from 'react';

// Image
import image from "../../../images/blessi-grow.png"

// Styles
import { 
    HeroContainer, 
    TextContent, 
    Title, 
    Subtitle,
    Paragraph, 
    Button,
    ButtonLink,
} from './HomeHeroElements';

const Hero = () => {
  return (
    <HeroContainer id='home'>
      <TextContent>
      <Subtitle>
      Chartered Management Accountant
      </Subtitle>
        <Title>
          Your Trusted Partner in Financial Success.
        </Title>
        <Paragraph>
          Are you struggling to keep your finances on track, or unsure if your business is reaching its full potential?
        </Paragraph>
        <Paragraph>
          I'm Emma, your financial navigator. I partner with SMEs to help them achieve their financial goals. From streamlining financial processes to providing insightful reporting, I can help you improve efficiencies and help your business to grow. 
        </Paragraph>
        <Paragraph>
          As a Chartered Management Accountant and 20 years’ experience, I have proven results of helping businesses thrive. With my independent expertise supporting your finances, you get to focus on what you enjoy and do best. 
        </Paragraph>
        <Paragraph>
          Your trusted financial partner, helping you drive your business forward. Your financial success is our priority.
        </Paragraph>
        <ButtonLink to="/contact"><Button>Start Today</Button>
        </ButtonLink>
      </TextContent>
    </HeroContainer>
  );
}

export default Hero;
