import React from "react";

import img from "../../../images/icons/reporting.svg"

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  Heading,
  Subtitle,
  Img,
  ImgWrap,
} from "./Service03Elements";

const Service03 = ({}) => {
  return (
    <>
      <InfoContainer >
        <InfoWrapper>
          <InfoRow >
            <Column1>
              <TextWrapper>
                <Heading >
                Insightful Reporting  
                </Heading>
                <Subtitle>
                Information is power. But sometimes, interpreting complex data can be a daunting task. At Blessi Accounting, we can help you understand raw data and extract meaningful insights. We specialise in creating reports that provide clarity, identify trends and uncover hidden opportunities within your data. From financial performance metrics to customer behaviour analysis, our reports are tailored to meet your specific needs and drive informed decision-making.
                </Subtitle>
                <Subtitle>
                With our expertise, you'll gain a deeper understanding of your business, enabling you to optimise processes and stay ahead of the competition. 
                </Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default Service03;
