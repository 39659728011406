import React from "react";

// Components
import Navbar from "../Navbar"
import HomeHero from "../AllPageSections/HomePageSections/HomeHero"
import Benefits01 from "../AllPageSections/HomePageSections/Benefit01";
import Benefits02 from "../AllPageSections/HomePageSections/Benefit02";
import Benefits03 from "../AllPageSections/HomePageSections/Benefit03";
import Testimonials from "../AllPageSections/HomePageSections/Testimonials";
import ContactBanner from "../ContactBanner"
import ImageBanner from "../ImageBanner";
import Footer from "../Footer";

const Home = () => {
 
    return (
      <>
      <Navbar />
      <HomeHero />
      <Benefits01 />
      <ContactBanner />
      <Benefits02 />
      <Benefits03 />
      <Testimonials />
      <ImageBanner />
      <Footer />

      </>
    );
  };

  
export default Home;