import React from "react";

// Components
import Navbar from "../Navbar"
import AboutHero from "../AllPageSections/AboutPageSections/AboutHero"
import AboutText from "../AllPageSections/AboutPageSections/AboutTextSection"
import AboutBoxSection from "../AllPageSections/AboutPageSections/AboutBoxSection";
import ImageBanner from "../ImageBanner";
import Footer from "../Footer";

const About = () => {
 
    return (
      <>
      <Navbar />
      <AboutHero />
      <AboutText />
      <ImageBanner />
      <AboutBoxSection />
      <Footer />

      </>
    );
  };

  
export default About;