import styled from 'styled-components';
import { Link as LinkR } from "react-router-dom";

export const BurgerButton = styled.button`
  display: block;
  background: none;
  border: none;
  cursor: pointer;
  color: #000;
  font-size: 20px;

  @media (min-width: 897px) {
    display: none; /* Hide the burger button on larger screens */
  }
`;

export const Menu = styled.ul`
  list-style-type: none;
  opacity: ${props => (props.open ? '1' : '0')};
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
  position: fixed;
  top: 115px;
  left: 50%;
  width: 50%;
  background-color: #fff;
  z-index: 1;
  height: ${props => (props.open ? '25vh' : '0')};
  overflow: hidden;
  transition: opacity 0.3s ease, height 0.3s ease-in;
  transform: translateX(-50%); /* Center the element horizontally */

  @media (min-width: 897px) {
    display: flex;
    justify-content: center;
    position: static;
    background-color: transparent;
    border: none;
    padding: 0;
    top: auto;
    height: auto;
    width: 50%;
    transform: none; /* Remove horizontal centering on larger screens */
  }
`;

export const MenuItem = styled.li`
  margin: 0 20px;

  @media (max-width: 897px) {
    margin: 10px 0;
  }
`;

export const NavLink = styled(LinkR)`
  color: #808385; /* Initial color */
  text-decoration: none;
  position: relative;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 0; /* Initial width */
    background-color: grey; /* Initial color */
    transition: width 0.5s ease, background-color 0.3s ease; /* Transition effect for width and color */
  }

  &:hover {
    color: #000; /* Color on hover */
  }


  &.active {
    color: #000; /* Change color for active link */
  }

`;

export const NavLink2 = styled(LinkR)`
  color: #6DC8DD; /* Initial color */
  text-decoration: none;
  position: relative;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 0; /* Initial width */
    background-color: grey; /* Initial color */
    transition: width 0.5s ease, background-color 0.3s ease; /* Transition effect for width and color */
  }

  &:hover {
    color: #6DC8DD; /* Color on hover */
  }

  &:hover::after {
    width: 100%; /* Final width */
    background-color: #000; /* Color on hover */
  }

  &.active {
    color: #fd7e14; /* Change color for active link */
    &::after {
      width: 100%; /* Ensure full width for active link */
      background-color: white; /* Color for active link */
    }
  }

`;