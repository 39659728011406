import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import GlobalStyle from './GlobalStyles';

// Import pages and sections
import Home from "./components/pages/home";
import About from "./components/pages/about";
import Services from "./components/pages/services";
import CaseStudies from "./components/pages/case-studies";
import CaseStudyDetailPage from "./components/pages/case-study-detail";
import Contact from "./components/pages/contact";

function App() {
  return (
    <>
      <GlobalStyle />
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/case-studies/:slug" element={<CaseStudyDetailPage />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
