import React, { useState } from 'react';
import {
 BurgerButton,
 Menu,
 MenuItem,
 NavLink,
 NavLink2,
} from "./BurgerMenuElements"


const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <>
      <BurgerButton onClick={toggleMenu}>☰</BurgerButton>
      <Menu open={isOpen}>
        <MenuItem>
          <NavLink to="/">
              Home
              </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/about">
          About
            </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/services">
            Services
            </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to="/case-studies">
          Case Studies
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink2 to="/contact">
            Contact</NavLink2>
        </MenuItem>
      </Menu>
    </>
  );
};

export default BurgerMenu;
