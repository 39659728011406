// ThreeColumnSection.js
import React from "react";
import {
    SectionContainer,
    Column,
    ColumnHeading,
    ColumnText,
    ColumnButton
} from "./ServicesBoxElements";

const ServicesBoxSection = () => {
  return (
    <SectionContainer>
      <Column>
        <ColumnHeading>About Blessi</ColumnHeading>
        <ColumnText>
        We are your trusted partner in navigating the complex world of finance to help you achieve your businesses financial goals effortlessly. 
        </ColumnText>
        <ColumnButton to="/about">Learn More</ColumnButton>
      </Column>
      
      <Column>
        <ColumnHeading>Case Studies</ColumnHeading>
        <ColumnText>
        At Blessi Accounting we excell in giving the best customer service. Just see what our clients say about us.
        </ColumnText>
        <ColumnButton to="/case-studies">Case Studies</ColumnButton>
      </Column>
      
      <Column>
        <ColumnHeading>Get In Touch</ColumnHeading>
        <ColumnText>
        Get in touch to discuss how we can become your trusted finance partner. 
        </ColumnText>
        <ColumnButton to="/contact">Get In Touch</ColumnButton>
      </Column>
    </SectionContainer>
  );
};

export default ServicesBoxSection;
