import styled from 'styled-components';
import { Link as LinkR } from "react-router-dom";

export const NavbarContainer = styled.nav`
  background-color: #fff;
  color: white;
  min-height: 75px; /* Set a minimum height for the navbar */
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center; /* Center items vertically */
  position: fixed; /* Position the navbar fixed at the top of the screen */
  top: 0; /* Align the navbar to the top of the screen */
  width: 100%; /* Make the navbar full width */
  z-index: 1000; /* Ensure the navbar is above other content */
  transition: height 0.5s ease;
  font-family: 'Roboto', sans-serif;

  @media (max-width: 897px) {
    flex-direction: row; /* Keep the direction row for smaller screens */
    justify-content: space-between; /* Space items evenly */
    align-items: center; /* Center items vertically */
    width: 80%; /* Make the navbar full width */
    padding-right: 150px; /* Make the navbar full width */
  }
`;


export const Logo = styled.img`
  height: 100px;
  width: 200px;
  margin-left: 50px;
  cursor: pointer;
`;

export const Menu = styled.ul`
  list-style-type: none;
  display: ${props => (props.open ? 'block' : 'none')};
  position: absolute; /* Position the menu absolutely */
  top: calc(80%); /* Position the menu below the navbar */
  width: 100%; /* Make the menu full width */
  background-color: #000;
  margin-right: 200px;
  z-index: 1; /* Ensure the menu is above other content */

  @media (min-width: 897px) {
    display: flex;
    justify-content: center; /* Center menu items horizontally */
    position: static; /* Reset position for larger screens */
    background-color: transparent; /* Make background transparent for larger screens */
    border: none; /* Remove border for larger screens */
    padding: 0; /* Remove padding for larger screens */
    top: auto; /* Reset top position for larger screens */
  }
`;

export const MenuItem = styled.li`
  margin: 0 20px; /* Adjust margin as needed */

  @media (max-width: 897px) {
    margin: 10px 0; /* Adjust margin for mobile view */
  }
`;

export const NavLink = styled(LinkR)`
  color: #808385; /* Initial color */
  text-decoration: none;
  position: relative;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 0; /* Initial width */
    background-color: grey; /* Initial color */
    transition: width 0.5s ease, background-color 0.3s ease; /* Transition effect for width and color */
  }

  &:hover {
    color: #000; /* Color on hover */
  }


  &.active {
    color: #000; /* Change color for active link */
  }

`;


export const NavLink2 = styled(LinkR)`
  color: #6DC8DD; /* Initial color */
  text-decoration: none;
  position: relative;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 0; /* Initial width */
    background-color: grey; /* Initial color */
    transition: width 0.5s ease, background-color 0.3s ease; /* Transition effect for width and color */
  }

  &:hover {
    color: #6DC8DD; /* Color on hover */
  }

  &:hover::after {
    width: 100%; /* Final width */
    background-color: #000; /* Color on hover */
  }

  &.active {
    color: #fd7e14; /* Change color for active link */
    &::after {
      width: 100%; /* Ensure full width for active link */
      background-color: white; /* Color for active link */
    }
  }

`;

