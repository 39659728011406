// ThreeColumnSectionElements.js
import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const SectionContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 60px 20px;
  background: #f9f9f9;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
  }
`;

export const Column = styled.div`
  flex: 1;
  max-width: 300px;
  margin: 0 20px;
  text-align: left;
  color: #333;

  @media screen and (max-width: 768px) {
    margin: 20px 0;
    max-width: 100%;
    text-align: center;
  }
`;

export const ColumnHeading = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 16px;
  color: #6DC8DD;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const ColumnText = styled.p`
  font-size: 1rem;
  margin-bottom: 20px;
  line-height: 1.5;
`;

export const ColumnButton = styled(LinkR)`
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background: #6DC8DD;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #808385;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
`;
