import React from "react";

import img from "../../../images/icons/personal.svg"

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  Img,
  ImgWrap,
  Button,
} from "./Benefit02Elements";

const InfoSection = ({}) => {
  return (
    <>
      <InfoContainer >
        <InfoWrapper>
          <InfoRow >
            <Column1>
              <TextWrapper>
                <TopLine>Tailored Financial Solutions</TopLine>
                <Heading >Personalised Approach</Heading>
                <Subtitle>
                We believe in taking the time to understand all aspects of your business and developing a strong partnership who is invested in your success. This includes gaining a deep insight into your operations, financial performance, and strategic goals. By doing so, we can provide you with the most accurate and relevant advice to help you make informed decisions. 
                </Subtitle>
                <BtnWrap>
                <Button to='/services'>Our Services</Button>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
