import React from "react";

import img from "../../../images/icons/insight.svg"

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  Img,
  ImgWrap,
  Button,
} from "./Benefit03Elements";

const InfoSection = ({}) => {
  return (
    <>
      <InfoContainer >
        <InfoWrapper>
          <InfoRow >
            <Column1>
              <TextWrapper>
                <TopLine>Expertise to help you drive sustainability and growth</TopLine>
                <Heading >Knowledge and Insight</Heading>
                <Subtitle>
                This involves not only interpreting numbers but also understanding the underlying factors that influence financial performance. This enables us to align financial strategies with the overall business objectives and contribute to long-term success. 
                </Subtitle>
                <BtnWrap>
                <Button to='/services'>Our Services</Button>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
