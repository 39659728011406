import React from "react";

// Components
import Navbar from "../Navbar"
import ServicesHero from "../AllPageSections/ServicesPageSections/ServicesHero"
import ServicesText from "../AllPageSections/ServicesPageSections/ServicesTextSection"
import ServicesBoxSection from "../AllPageSections/ServicesPageSections/ServicesBoxSection";
import Service01 from "../AllPageSections/ServicesPageSections/Service01";
import Service02 from "../AllPageSections/ServicesPageSections/Service02";
import Service03 from "../AllPageSections/ServicesPageSections/Service03";
import Service04 from "../AllPageSections/ServicesPageSections/Service04";
import Footer from "../Footer";

const Services = () => {
 
    return (
      <>
      <Navbar />
      <ServicesHero />
      <ServicesText />
      <Service01 />
      <Service02 />
      <Service03 />
      <Service04 />
      <ServicesBoxSection />
      <Footer />

      </>
    );
  };

  
export default Services;