import React from "react";

// Import Images
import XAC from "../images/logos/XAC.png"; // Replace with your image paths
import CIMA from "../images/logos/CIMA.jpeg"; // Replace with your image paths

// Import Styles
import { BannerContainer, BannerImage } from "./ImageBannerElements";



const ImageBanner = () => {
  return (
    <BannerContainer>
      <BannerImage src={CIMA} alt="CIMA practising certificate logo" />
      <BannerImage src={XAC} alt="Xero cert advisor logo" />
    </BannerContainer>
  );
};

export default ImageBanner;
