// HeroElements.js
import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import image from "../../../images/banner.png"

export const HeroContainer = styled.div`
  background-image: url(${image}); // Set your background image URL
  background-size: cover; // Adjust the size of the background image
  background-repeat: no-repeat; // Prevent the image from repeating
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 50vh; /* Adjust height as needed */
  color: #fff;
  text-align: center;
  padding: 50px;
  margin-top: 50px;

  @media screen and (max-width: 768px) {
    height: auto;
    padding: 100px 20px;
  }
`;

export const HeroHeading = styled.h1`
  font-size: 3rem;
  margin-bottom: 20px;
  color: #000;

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 40px;
  max-width: 600px;
  color: #000;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
`;

export const HeroButton = styled(LinkR)`
  text-decoration: none;
  padding: 12px 30px;
  font-size: 1.2rem;
  color: #fff;
  background: #808385;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background 0.3s ease;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    padding: 10px 24px;
  }
`;
