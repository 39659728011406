// Banner.js
import React from "react";
import {
    BannerContainer,
    BannerContent,
    BannerText,
    BannerButton
} from "./ContactBannerElements";

const Banner = () => {
  return (
    <BannerContainer>
      <BannerContent>
        <BannerText>Get in touch with Blessi Accounting to explore how we can transform your financial outlook and propel your business forward.</BannerText>
        <BannerButton to="/contact">Start Today</BannerButton>
      </BannerContent>
    </BannerContainer>
  );
};

export default Banner;
