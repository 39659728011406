import React from "react";
import { animateScroll as scroll } from "react-scroll";
import Logo from "../images/logos/blessiB.svg";
import SecuredBy from "../SecuredBy";

// Files
import PP from "../files/Blessi-Privacy-Policy.pdf"

// Social icons
import { FaLinkedin } from "react-icons/fa";

// Styled components
import {
  FooterContainer,
  NavLogoImage,
  FooterWrap,
  FooterLinkContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIconLink,
  CompanyInfo,
} from "./FooterElements";

const Footer = ({ isFilterActive }) => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer isFilterActive={isFilterActive}>
      <FooterWrap>
        <FooterLinkContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Site Map</FooterLinkTitle>
              <FooterLink to="/">Home</FooterLink>
              <FooterLink to="/about">About</FooterLink>
              <FooterLink to="/services">Services</FooterLink>
              <FooterLink to="/case-studies">Case Studies</FooterLink>
              <FooterLink to="/contact">Contact</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Contact</FooterLinkTitle>
              <FooterLink to="/contact">Contact</FooterLink>
              <FooterLink to={`mailto:${'enquiries@blessiaccounting.co.uk'}`}>
                enquiries@<br />blessiaccounting.co.uk
              </FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Social</FooterLinkTitle>
          <SocialIconLink
            href="https://www.linkedin.com/company/blessi-accounting/"
            target="_blank"
            aria-label="LinkedIn"
          >
            <FaLinkedin />
          </SocialIconLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Legal</FooterLinkTitle>
              <FooterLink to={PP} target="_blank">
                Privacy Policy
              </FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinkContainer>
        <SocialMediaWrap>
          <SocialLogo to="/" onClick={toggleHome}>
            <NavLogoImage src={Logo} alt="Blessi Logo" />
          </SocialLogo>
        </SocialMediaWrap>
        <WebsiteRights>
          Blessi Accounting LTD &copy; {new Date().getFullYear()} All Rights
          Reserved
        </WebsiteRights>
        <CompanyInfo>
          Registered in England and Wales | Company Registration No: 15702890
        </CompanyInfo>
        <SecuredBy />
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
