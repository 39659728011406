import styled from 'styled-components';
import { Link as LinkR } from "react-router-dom";

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 80vh;
  height: auto;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  color: white; /* Set text colour to white for better contrast */

  @media (max-width: 805px) {
    flex-direction: column;
    padding: 10px;
  }
`;

export const TextContent = styled.div`
  flex: 1;
  color: #000;
  margin-right: 50px;
  padding-left: 100px;
  padding-top: 80px;
  max-width: 90%;

  @media (max-width: 805px) {
    margin-top: 50px;
    padding-left: 0;
    margin-right: 0;
  }
`;

export const ImageContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;

  @media (max-width: 805px) {
    padding: 20px;
  }
`;

export const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  // width: 60%; /* Adjust the width as needed */
  // object-fit: cover; /* This ensures the image scales properly without distortion */
`;

export const Subtitle = styled.p`
  font-size: 1rem;
  margin-bottom: -20px;
  margin-top: 50px;
  color: #6DC8DD;
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

export const Paragraph = styled.p`
  font-size: 1.2rem;
  margin-bottom: 20px;
`;

export const ButtonLink = styled(LinkR)`
  display: flex;
  justify-content: flex-end;
  text-decoration: none;
`;

export const Button = styled.button`
  padding: 15px 25px;
  background-color: #6DC8DD;
  border: none;
  border-radius: 5px;
  font-size: 0.8rem;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
  transition: all 0.3s ease-in-out;
  
  &:hover {
    background-color: #808385;
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
`;
