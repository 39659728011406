import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const FooterContainer = styled.footer`
  background-color: #1c1c1e;
  padding: 4rem 0;
  color: #fff;
`;

export const FooterWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 24px;
`;

export const NavLogoImage = styled.img`
  cursor: pointer;
  width: 120px;
  margin-bottom: 1rem;
`;

export const FooterLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  width: 160px;
  color: #f1f1f1;
  box-sizing: border-box;

  @media screen and (max-width: 420px) {
    align-items: center;
    margin: 0;
    width: 100%;
    padding: 10px;
  }
`;

export const FooterLinkTitle = styled.h2`
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: bold;
`;

export const FooterLink = styled(LinkR)`
  color: #f1f1f1;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  transition: color 0.3s ease;

  &:hover {
    color: #6DC8DD;
  }
`;

export const FooterA = styled.a`
  color: #f1f1f1;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  transition: color 0.3s ease;

  &:hover {
    color: #000;
  }
`;

export const FooterScroll = styled(LinkR)`
  color: #f1f1f1;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  transition: color 0.3s ease;

  &:hover {
    color: #000;
  }
`;

export const SocialMedia = styled.section`
  width: 100%;
  margin-top: 2rem;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SocialLogo = styled(LinkR)`
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;

  &:hover {
    color: #6DC8DD;
    transition: color 0.3s ease;
  }
`;

export const WebsiteRights = styled.small`
  color: #f1f1f1;
  margin-bottom: 16px;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
  margin-right: 15px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.2);
    color: #6DC8DD;
  }
`;

export const CompanyInfo = styled.p`
  color: #f1f1f1;
  margin-top: 24px;
  text-align: center;
  font-size: 14px;
  max-width: 600px;
`;
