// CaseStudyPage.js
import React from "react";
import { caseStudies } from "../../../data";
import StarRating from "../../../StarRating"

import {
  CaseStudyContainer,
  CaseStudyWrapper,
  CaseStudyItem,
  CaseStudyImage,
  CaseStudyContent,
  CaseStudyTitle,
  CaseStudyDescription,
  CaseStudyLink
} from "./CaseStudyElements";



const CaseStudySection = () => {
  return (
    <CaseStudyContainer>
      <h1>Case Studies</h1>
      <CaseStudyWrapper>
        {caseStudies.map(({ id, miniquote, company, image, slug }) => (
          <CaseStudyItem key={id}>
            <CaseStudyImage src={image} alt={company} />
            <CaseStudyContent>
              <CaseStudyTitle>{miniquote}</CaseStudyTitle>
              <StarRating  rating={5}/>
              <CaseStudyDescription>{company}</CaseStudyDescription>
              <CaseStudyLink to={slug}>Read More</CaseStudyLink>
            </CaseStudyContent>
          </CaseStudyItem>
        ))}
      </CaseStudyWrapper>
    </CaseStudyContainer>
  );
};

export default CaseStudySection;
