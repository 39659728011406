// BannerElements.js
import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const BannerContainer = styled.div`
  background: linear-gradient(135deg, #808385, #1c1c1e);
  padding: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  color: #fff;
`;

export const BannerContent = styled.div`
  display: flex;
  align-items: center;
  max-width: 1100px;
  width: 100%;
  padding: 0 24px;
`;

export const BannerText = styled.h1`
  font-size: 1.7rem;
  margin-bottom: 0;
  max-width: 800px;
  line-height: 1.2;
  flex: 1;
`;

export const BannerButton = styled(LinkR)`
  padding: 12px 24px;
  font-size: 1.2rem;
  color: #fff;
  background: #6DC8DD;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-left: 20px;

  &:hover {
    background: #808385;
  }
`;
