import React from "react";

// Components
import Navbar from "../Navbar"
import CaseStudiesHero from "../AllPageSections/CaseStudyPageSections/CaseStudyHero"
import CaseStudyBoxSection from "../AllPageSections/CaseStudyPageSections/CaseStudyBoxSection";
import CaseStudyDetail from "../AllPageSections/CaseStudyPageSections/CaseStudyDetail";
import Footer from "../Footer";

const CaseStudyDetailPage = () => {
 
    return (
      <>
      <Navbar />
      <CaseStudiesHero />
      <CaseStudyDetail />
      <CaseStudyBoxSection />
      <Footer />

      </>
    );
  };

  
export default CaseStudyDetailPage;