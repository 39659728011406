// CaseStudyElements.js
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

export const CaseStudyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  background-color: #f5f5f5;
  min-height: auto;
`;

export const CaseStudyWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
`;

export const CaseStudyItem = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 20px;
  flex: 1 1 calc(33% - 40px);
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  @media screen and (max-width: 768px) {
    flex: 1 1 calc(50% - 40px);
  }

  @media screen and (max-width: 480px) {
    flex: 1 1 100%;
  }
`;

export const CaseStudyImage = styled.img`
  width: auto;
  height: auto;
  object-fit: fit;
`;

export const CaseStudyContent = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const CaseStudyTitle = styled.h2`
  font-size: 18px;
  font-style: italic;
  margin-bottom: 10px;
  color: #333;
`;

export const CaseStudyDescription = styled.p`
  font-size: 16px;
  color: #666;
  flex-grow: 1;
`;

export const CaseStudyLink = styled(RouterLink)`
  align-self: flex-start;
  text-decoration: none;
  color: #6DC8DD;
  font-weight: bold;
  margin-top: 20px;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #808385;
  }
`;
