import React from "react";

import img from "../../../images/icons/planning.svg"

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  Img,
  ImgWrap,
  Button,
} from "./Benefit01Elements";

const InfoSection = ({}) => {
  return (
    <>
      <InfoContainer >
        <InfoWrapper>
          <InfoRow >
            <Column1>
              <TextWrapper>
                <TopLine>Independent Support and Advice</TopLine>
                <Heading >Expertise in Strategic Accounting </Heading>
                <Subtitle>
                  We effectively analyse and interpret financial data to provide strategic insights for decision-making. Our proficiency in cashflow, budgeting, forecasting and cost analysis allows us to develop comprehensive financial strategies that align with your business goals and drive growth. 
                </Subtitle>
                <BtnWrap>
                  <Button to='/about'>About Us</Button>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
