import React from "react";
import styled from "styled-components";
import SHLogo from "../images/logos/SHFooterLogo.png"

const SecuredBy = () => {
  return (
    <SecuredByContainer>
      <SecuredByText>Designed & Secured By</SecuredByText>
      <CompanyLink href="https://strongholdtech.co.uk/" target="_blank" rel="noopener noreferrer">
        <CompanyLogo src={SHLogo} altText="Stronghold Tech" />
      </CompanyLink>
    </SecuredByContainer>
  );
};

const SecuredByContainer = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  margin-top: 16px;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

const SecuredByText = styled.span`
  margin-right: 5px;
`;

const CompanyLink = styled.a`
  text-decoration: none;
`;

const CompanyLogo = styled.img`
  width: 200px; /* Adjust the width as needed */
`;

export default SecuredBy;
