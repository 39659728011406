// AboutTextElements.js
import styled from "styled-components";

export const AboutTextContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px 20px 50px;
  color: #333;
  line-height: 1.6;

  @media screen and (max-width: 768px) {
    padding: 60px 20px;
  }
`;

export const AboutHeading = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 24px;
  color: #6DC8DD;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const AboutParagraph = styled.p`
  font-size: 1.2rem;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;
