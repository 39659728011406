// Imported Images
import DUK from "./images/logos/DUK.jpeg"
import RTP from "./images/logos/RTP2.png"
import NR from "./images/logos/NR.png"

export const caseStudies = [
  {
    id: 1,
    slug: "The-impact-of-centralising-and-streamlining-financial-operations",
    title: "The impact of centralising and streamlining financial operations",
    overview: "Dimensions UK support people with learning disabilities and autistic people across much of the country to have a louder voice, choice and control in their lives. Their workforce delivers ambitious, personalised support and housing, often with those whose previous support has not been successful. The organisation faced challenges when their regional financial operations were centralised. There were inconsistent processes across various teams and a restructure was required to oversee the forecasting, budgeting, and costing processes.  ",
    challenge: "The organisation had a fragmented regional finance structure, with inconsistent processes and a lack of centralised control. The need to centralise financial operations and streamline processes was paramount to improve efficiency and ensure accurate costing of new work. In addition, there was some resistance from various teams to adopt new processes and take on additional tasks.",
    solution: "Emma led a comprehensive restructuring of the financial operations, centralising functions and coordinating the movement of tasks across different teams and working with different levels of staff. She streamlined processes, managed difficult conversations to overcome resistance, and implemented robust reporting mechanisms to track progress and ensure accountability.",
    bulletPoints: [
      "Coordinating the centralisation of the finance function, working with a smaller, more efficient team. Emma facilitated the transfer of tasks to operational colleagues and other finance team members, ensuring a smooth transition.",
      "All existing processes were reviewed, inconsistencies were identified, and best practices were implemented. Emma developed workflow processes and RACI (Responsible, Accountable, Consulted, Informed) documents to ensure clarity and consistency.",
      "Stakeholder engagement with various operational and finance teams was undertaken, as well as the project management team, to ensure alignment and buy-in. Emma led meetings, planned next steps, and maintained strong communication channels to address any concerns and ensure smooth implementation.",
      "Reporting was enhanced by improving the visibility of a self-service reporting mechanism. This allowed colleagues to access necessary information independently, reducing the reliance on finance teams, supporting capacity conversations and improving overall productivity.",
      "An ethical and thoughtful approach was consistently demonstrated throughout the process to help influence decision-making and problem-solving. Emma always considered the right way to handle processes and accounting treatments, ensuring the best outcomes for the organisation.",
    ],
    outcome: "Emma’s leadership and innovative approach significantly transformed Dimension UK’s financial operations. The centralisation and streamlining of processes led to improved efficiency, better productivity management, and enhanced financial visibility. Emma’s efforts ensured that the organisation continued to benefit from the improvements she implemented.",
    quote: "Emma managed our forecasting, budgeting, and costing processes during a complete restructure of our financial operations. Her ability to centralise functions, streamline processes, and engage with stakeholders was instrumental in driving continuous improvement. Emma’s ethical approach and determination to achieve the best outcomes make her one of the most thoughtful and capable professionals I’ve ever worked with. Her contributions have had a lasting positive impact on our organisation.",
    miniquote:"'one of the most thoughtful and capable professionals I’ve ever worked with'",
    name: "Claire Samways",
    role: "Director of Commercial Finance",
    company: "Dimensions UK",
    image: DUK
  },
  {
    id: 2,
    slug: "5-star review on 5-year 3 way forecast",
    title: "5-star review on 5-year 3 way forecast",
    overview: "Request to Pay Limited (RtP) is part of the flexible payment initiative with fraud detection. They allow consumers to pay bills in a way that best suits their financial situation, all while maintaining essential services. Unlike traditional direct debit systems, RtP enables a dialogue between payer and payee, fostering flexibility and engagement.",
    challenge: "RtP aimed to expand its operations and ensure long-term sustainability. They sought an external expert to independently review their current financial position and develop a comprehensive five-year business plan. This is where Blessi Accounting, led by Emma, stepped in to provide the needed expertise.",
    solution: "Emma was commissioned to spearhead this project. Despite a tight timeline, Emma took a thorough and methodical approach to understanding the industry, asking critical questions, and challenging RtP’s initial assumptions.",
    bulletPoints: [
      "Conducted in-depth fact-finding sessions to understand RtP’s business model and market dynamics.",
      "Developed a detailed spreadsheet incorporating various revenue streams, complex data sets, staff costs, and other expenses, such as pensions.",
      "Created a comprehensive profit and loss statement, balance sheet and cash flow projection for a five-year period.",
      "Provided a summary sheet which included a dashboard of key ratios and charts, ensured the plan could be easily adjusted over time so inputs/assumptions could be easily amended as and when required.",
      "Created a simplified monthly projection across the five-year period.",
      "Offering to present the findings in multiple formats to cater to the diverse expertise of RtP’s senior team. ",
    ],
    outcome: "Emma’s meticulous attention to detail and flexible approach yielded impressive results. The RtP senior team was able to clearly understand their financial standing and future prospects. The detailed business plan not only met the agreed timescale and budget but also exceeded expectations, providing a solid foundation for RtP’s growth ambitions. Furthermore, the robustness of the plan helped RtP present to interested partners and potential venture capitalists (VCs) with the hope of securing funding to help grow the business.",
    quote: "Emma demonstrated meticulous detail in the work she produced for us. She listened carefully to what we thought we needed, asked lots of questions, and really got to know and understand our requirements. She helped challenge our assumptions and produced a plan that showcased complex revenue streams in an understandable way for non-financial leaders. Her work helped us present a detailed and robust plan, gain insights and demonstrate potential to interested partners, potential VCs and funders.",
    miniquote:"'Meticulous attention to detail and flexible approach yielded impressive results'",
    name: "Andy Coleyshaw",
    role: "Chief Executive Officer",
    company: "Request to Pay Limited",
    image: RTP
  },
  {
    id: 3,
    slug: "Financial-restructuring-with-an-innovative-data-management-system",
    title: "Financial restructuring with an innovative data management system",
    overview: "Norwood Ravenswood, one of the largest Jewish social care organisations in the UK, provides essential services for individuals with learning difficulties and autism, along with support for their families. The organisation had traditionally relied heavily on fundraising, leading to a need for a more balanced financial strategy.",
    challenge: "Norwood Ravenswood’s financial structure was traditionally set up with finance acting as a gatekeeper rather than being integral to front-office activities such as local authority engagement. The organisation needed to shift towards a more sustainable financial model that leveraged both statutory income and fundraising efforts.",
    solution: "Emma led a comprehensive financial restructuring and implemented an innovative data management system tailored to Norwood Ravenswood’s needs. Her approach was characterised by meticulous planning, strategic negotiations, and the development of practical solutions using existing tools.",
    bulletPoints: [
      "Emma redefined the role of finance within the organisation, ensuring it became integral to local authority and funding body engagements. She led negotiations with experienced procurement officers, securing more favourable terms and increasing statutory income.",
      "Recognising the absence of a Customer Relationship Management (CRM) system, Emma developed an innovative solution using existing tools. This system allowed for the effective management of personal and sensitive data, facilitating business intelligence and customer journey mapping.",
      "Through her efforts, Norwood Ravenswood realised an incremental value of £4 million in the financial year ending March 2024, demonstrating the substantial impact of the restructuring and new system."
    ],
    outcome: "Emma’s expertise and innovative approach significantly transformed Norwood Ravenswood’s financial management. The organisation achieved a more balanced and sustainable financial model, integrating finance into core operations and improving engagement with local authorities. The newly developed data management system, that is still used today, provides valuable insights and improved the management of service user data, leading to better decision-making and enhanced service delivery.",
    quote: "I’ve worked with over 200 accountants in my career, and Emma comfortable makes the top five – her skillset is not often seen in typical accountants, personable, pragmatic, innovative, persuasive and results focused with the highest degree of personal empathy.  It was a pleasure to have worked alongside Emma and has given Norwood a delightful legacy.",
    miniquote:"'Results focused with the highest degree of personal empathy.'",
    name: "Patrick Murphy",
    role: "Finance & Corporate Services Director",
    company: "Norwood Ravenswood",
    image: NR
  }
];
