import React from "react";

import img from "../../../images/icons/masterFinancial.svg"

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  Heading,
  Subtitle,
  Img,
  ImgWrap,
} from "./Service02Elements";

const Service02 = ({}) => {
  return (
    <>
      <InfoContainer >
        <InfoWrapper>
          <InfoRow >
            <Column1>
              <TextWrapper>
                <Heading >
                Mastering Financial Success
                </Heading>
                <Subtitle>
                This service is tailored to address your most pressing financial concerns, from inaccurate costings to unreliable forecasts. With our expertise in cost management, budgeting, forecasting and cashflow optimisation, we ensure that your financial strategies are finely tuned to drive profitability and sustainability. 
                </Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default Service02;
