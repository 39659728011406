import React from "react";

import img from "../../../images/icons/streamlining.svg"

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  Heading,
  Subtitle,
  Img,
  ImgWrap,
} from "./Service04Elements";

const Service04 = ({}) => {
  return (
    <>
      <InfoContainer >
        <InfoWrapper>
          <InfoRow >
            <Column1>
              <TextWrapper>
                <Heading >
                Streamlining 
                </Heading>
                <Subtitle>
                We believe simplicity is the key to unlocking productivity and driving growth. With our expertise we'll work closely with your team to identify inefficiencies and streamline workflows. From automating repetitive tasks to reengineering outdated processes, we'll help you achieve new levels of efficiency and effectiveness.  

By streamlining your processes, you'll not only save time and money but also enhance agility, responsiveness and customer satisfaction.  
                </Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default Service04;
