// AboutText.js
import React from "react";
import { TextContainer, Heading, Paragraph } from "./ServicesTextElements";

const ServicesText = () => {
  return (
    <TextContainer>
      <Heading>Blessi Services</Heading>
      <Paragraph>
      Whether you're struggling with cash flow issues, aiming to increase profitability, or planning for future growth, we're here to help. 
      </Paragraph>
      <Paragraph>
      Emma is a Chartered Management Accountant who specialises in providing comprehensive financial solutions, tailored to meet the specific needs of your business. 
      </Paragraph>
      <Paragraph>
      By taking the time to listen to your concerns and learn about your business, we can develop a bespoke financial strategy that addresses your pain points and aligns with your long-term goals. From cost analysis and budgeting to insightful reporting, we'll work with you to guide you through the complexities of financial management and unlock your businesses full potential. 
      </Paragraph>
    </TextContainer>
  );
};

export default ServicesText;
